<template>
    <div id="account_manage">
        <jy-query ref="form" :model="form">
            <jy-query-item prop="userNa" label="登录账号:">
                <el-input placeholder="请输入" v-model="form.userNa"></el-input>
            </jy-query-item>
            <!-- <jy-query-item prop="roleId" label="所属角色:">
                <el-select v-model="form.roleId" placeholder="请选择">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="item in statusList" :key="item.roleId" :label="item.roleNa" :value="item.roleId"></el-option>
                </el-select>
            </jy-query-item> -->
            <jy-query-item prop="markNa" label="用户姓名">
                <el-input placeholder="请输入" v-model="form.markNa"></el-input>
            </jy-query-item>
            <jy-query-item prop="phone" label="手机">
                <el-input placeholder="请输入" v-model="form.phone"></el-input>
            </jy-query-item>
            <jy-query-item prop="orgId" label="所属机构:">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('wastesSystemSettingsAccountManagementList')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('wastesSystemSettingsAccountManagementReset')">重置</el-button>
                <el-row>
                    <el-button type="primary" v-on:click="addUpdate()" v-if="btnexist('wastesSystemSettingsAccountManagementInsert')">新增</el-button>
                    <!-- <el-button type="primary">批量删除</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table :data="dataList" v-loading="loading">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <!-- <jy-table-column type="selection" width="70"></jy-table-column> -->
            <jy-table-column prop="userNa" label="登录账号" min-width="120"></jy-table-column>
            <jy-table-column prop="markNa" label="用户名称" min-width="120"></jy-table-column>
            <jy-table-column prop="orgNa" label="所属组织" min-width="200"></jy-table-column>
            <jy-table-column prop="roleNa" label="所属角色" min-width="160"></jy-table-column>
            <jy-table-column label="企业logo" min-width="100">
                <template slot-scope="scope">
                    <el-image :src="scope.row.image" class="logo_img" fit="cover" :preview-src-list="[scope.row.image]">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </template>
            </jy-table-column>
            <jy-table-column label="账号状态" min-width="80">
                <template slot-scope="scope">{{ scope.row.active | state }}</template>
            </jy-table-column>
            <jy-table-column prop="createT" label="创建日期" min-width="160"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <jy-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-size="pageSize"
            :total="total"
        ></jy-pagination>
        <add-update-account ref="addUpdateAccount" @updateList="getList"></add-update-account>
        <reset-password ref="pwd"></reset-password>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择上级机构"></institutions-tree>
    </div>
</template>
<script>
    import addUpdateAccount from "@/components/pages/admin/systemSettings/accountManagement/addUpdateAccount";
    import ResetPassword from "@/components/pages/admin/systemSettings/accountManagement/ResetPassword.vue";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    import { mapGetters } from "vuex";
    import { btnMixins } from "@/common/js/button.mixin";

    export default {
        data() {
            return {
                statusList: [],
                form: {
                    userNa: "",
                    roleId: "",
                    phone: "",
                    markNa: "",
                    orgId: "",
                    orgNa: ""
                },
                pageSize: 10,
                total: 10,
                pageIndex: 1,
                dataList: [],
                operateList: [],
                loading:false,

                btnMenuId: "wastesSystemSettingsAccountManagement"
            };
        },
        mixins: [btnMixins],
        components: {
            addUpdateAccount,
            ResetPassword,
            institutionsTree
        },
        activated() {
            this.getAllList();
        },
        created() {
            this.getList();
            this.setOperateList();
        },
        filters: {
            state(val) {
                if (val == 1) {
                    return "启用";
                } else {
                    return "禁用";
                }
            }
        },
        computed: {
            ...mapGetters(["userInfoGet"])
        },
        methods: {
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            // 上级机构查询
            addInstitutions(data) {
                this.form.orgId = data.orgId;
                this.form.orgNa = data.orgNa;
            },
            setOperateList() {
                let l = [
                    {
                        name: "重置密码",
                        icon: "el-icon-edit-outline",
                        fun: this.resetPassword,
                        isShow: () => {
                            return this.btnexist("wastesSystemSettingsAccountManagementResetPwd");
                        }
                    },
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.addUpdate,
                        isShow: () => {
                            return this.btnexist("wastesSystemSettingsAccountManagementUpdate");
                        }
                    },
                    {
                        name: "强制下线",
                        icon: "el-icon-edit-outline",
                        fun: this.logoutUser,
                        isShow: () => {
                            return true;
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: () => {
                            return this.btnexist("wastesSystemSettingsAccountManagementDelete");
                        }
                    }
                ];
                this.operateList = l;
            },
            getAllList() {
                let option = {
                    pageIndex: 1,
                    pageSize: 10000,
                    sysRole: {}
                };
                let url = "/sys/role/pageQueryRole";
                this.$http.post(url, option).then(({ detail }) => {
                    if (detail) {
                        // this.statusList = [{ roleNa: "全部", roleId: "" }];
                        this.statusList = detail.list.map(v => v.sysRole);
                    }
                });
            },
            getList() {
                let url = "/sys/user/pageQuerySysUser";
                this.loading = true
                this.$http
                    .post(
                        url,
                        {
                            sysUser: this.form,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        },
                        { isRequestParam: false }
                    )
                    .then(({ detail }) => {
                        this.loading = false
                        this.dataList = detail.list.map(v => {
                            return {
                                ...v.sysOrg,
                                ...v.sysRole,
                                ...v.sysUser,
                                ...v.sysUserEx,
                                createT: this.$util.formatMacTime(v.sysUser.createT)
                            };
                        });
                        console.log(detail);
                        this.total = detail.total;
                    },()=>{
                        this.loading = false
                    });
            },
            // 查询表格
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.orgNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            // 删除
            handleDelete(row) {
                this.$confirm("是否删除该账号?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/sys/user/delete";
                        this.$http.post(url, row.userId, { isRequestParam: false }).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            logoutUser(row) {
                this.$confirm("是否强制下线该账号?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/sys/user/loginOutByUserId";
                        this.$http.post(url, row.userId, { isRequestParam: false }).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            addUpdate(option) {
                if (option) {
                    this.$refs.addUpdateAccount.init(option, "update");
                } else {
                    this.$refs.addUpdateAccount.init();
                    this.$refs.addUpdateAccount.setAllRoles(this.statusList);
                }
            },
            resetPassword(row) {
                this.$refs.pwd.init(row);
                // this.$confirm("是否重置该账号密码?", "提示", {
                //     confirmButtonText: "确定",
                //     cancelButtonText: "取消",
                //     type: "warning"
                // })
                //     .then(() => {
                //         let url = "/sys/user/resetPassword";
                //         this.$http.post(url, row.userId, { isRequestParam: false }).then(() => {
                //             this.$message({
                //                 type: "success",
                //                 message: "该账号重置后的密码为123456！"
                //             });
                //             this.getList();
                //         });
                //     })
                //     .catch(() => {
                //         this.$message({
                //             type: "info",
                //             message: "已取消重置"
                //         });
                //     });
            }
        }
    };
</script>
<style scoped="accountManagement" lang="scss">
    #account_manage {
        .logo_img {
            width: 60px;
            height: 60px;

            i {
                font-size: 60px;
            }
        }
    }
</style>
