<template>
    <el-dialog :visible.sync="visible" title="重置密码" @close="close" width="350px">
        <el-form ref="pwdForm" :model="model" label-width="80px" size="small" :rules="rules">
            <el-form-item label="新密码" prop="passwd">
                <el-input v-model="model.passwd" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirm">
                <el-input v-model="model.confirm" show-password></el-input>
            </el-form-item>
        </el-form>

        <div class="pwd_buttons">
            <el-button type="primary" size="small" @click="makeSure">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        methods: {
            close() {
                this.model = {
                    passwd: "",
                    confirm: ""
                };
            },
            init(row) {
                this.model.userId = row.userId;
                this.visible = true;
            },

            makeSure() {
                this.$refs.pwdForm.validate(valide => {
                    if (valide) {
                        let params = {
                            passwd:this.model.passwd,
                            userId:this.model.userId
                        }
                        this.$http.post("/sys/user/updatePassword",params).then(res => {
                            this.$message.success({
                                message: res.msg
                            });
                            this.visible = false;
                        });
                    }
                });
            }
        },
        data() {
            return {
                visible: false,
                model: {
                    passwd: "",
                    confirm: ""
                },

                rules: {
                    passwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
                    confirm: [
                        { required: true, message: "请输入确认密码", trigger: "blur" },
                        {
                            validator: (rule, value, callback) => {
                                if (value !== this.model.passwd) {
                                    callback(new Error("输入的确认密码需要和新密码保持一致"));
                                } else {
                                    callback();
                                }
                            },
                            trigger: "blur"
                        }
                    ]
                }
            };
        }
    };
</script>
<style scoped>
    .pwd_buttons {
        text-align: center;
    }
</style>
