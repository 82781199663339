<template>
    <el-dialog :visible.sync="visible" title="选择企业单位">
        <div>
            <div class="search_area">
                <span>企业名称</span>
                <el-input v-model="cName" size="small"></el-input>
            </div>
            <el-button size="small" @click="search">查询</el-button>
        </div>
        <jy-table :data="dataList">
            <jy-table-column label="企业名称" prop="cName"></jy-table-column>
            <jy-table-column label="所属机构" prop="orgNa"></jy-table-column>
            <jy-table-column label="监管机构" prop="mOrgNa"></jy-table-column>
            <jy-table-column label="状态" prop="status"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <jy-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-size="pageSize"
            :total="total"
        ></jy-pagination>
    </el-dialog>
</template>

<script>
    export default {
        methods: {
            init() {
                this.visible = true;
                this.getList();
            },
            search() {
                this.pageIndex = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            getList() {
                this.loading = true;
                let url = "/unitInfo/pageQuery";
                this.$http
                    .post(
                        url,
                        {
                            unitInfo: {
                                cName: this.cName,
                                regionId: -1,
                                orgId: "",
                                orgNa: "",
                                mOrgId: "",
                                mOrgNa: "",
                                creditCode: "",
                                nature: "",
                                status: "",
                                industry: "",
                                wUnitType: "",
                                cardNo: "",
                                contractT: [],
                                unitType: ""
                            },
                            orgIds: [],
                            mOrgIds: [],
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        },
                        { isRequestParam: false }
                    )
                    .then(({ detail }) => {
                        this.dataList = detail.list.map(v => {
                            return {
                                ...v.unitInfo,
                                orgId: v.sysOrg.orgId,
                                orgNa: v.sysOrg.orgNa,
                                mOrgId: v.mSysOrg.orgId,
                                mOrgNa: v.mSysOrg.orgNa
                            };
                        });
                        this.total = detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            selectRow(row) {
                this.$emit("select", row);
                this.visible = false;
            }
        },
        data() {
            return {
                visible: false,

                operateList: [
                    {
                        name: "选择",
                        icon: "el-icon-edit-outline",
                        fun: this.selectRow,
                        isShow: () => {
                            return true;
                        }
                    }
                ],
                dataList: [],
                total: 0,
                pageIndex: 1,
                pageSize: 10,
                cName: ""
            };
        }
    };
</script>
<style scoped>
    .search_area {
        display: inline-block;
    }
    .el-input {
        width: 180px;
        margin: 0 10px;
    }
</style>
